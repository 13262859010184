import React from "react"
import { NationalPark } from "../../models/NationalPark"
import TailwindCard from "../cards/TailwindCard"
import { connectHits } from "react-instantsearch-dom"

interface Props {
  hits: HitData[]
}

interface HitData {
  uid: string
  tags: string[]
  data: NationalPark
}

const MyHits: React.FunctionComponent<Props> = ({ hits }) => {
  return (
    <div className="grid grid-cols-1 md:grid-cols-3 py-4">
      {hits.map((item, index) => {
        return (
          <div className="mr-2" key={index.toString()}>
            <TailwindCard
              park={item.data}
              parkLink={item.uid}
              tags={item.tags}
            />
          </div>
        )
      })}
    </div>
  )
}

export default connectHits(MyHits)
