import React from "react"
import algoliasearch from "algoliasearch/lite"
import { InstantSearch, SearchBox } from "react-instantsearch-dom"

import "instantsearch.css/themes/reset.css"
import "instantsearch.css/themes/satellite.css"
import MyHits from "./MyHits"
import SmallContainer from "../layout/SmallContainer"
import MySearchbar from "./MySearchbar"

const searchClient = algoliasearch(
  process.env.GATSBY_ALGOLIA_APP_ID,
  process.env.GATSBY_ALGOLIA_SEARCH_KEY
)

export default function AlgoliaSearchbar() {
  return (
    <InstantSearch searchClient={searchClient} indexName="Parks">
      <SmallContainer center={true} className="py-0">
        <MySearchbar />
      </SmallContainer>
      <div className="grid py-4">
        <MyHits />
      </div>
    </InstantSearch>
  )
}
