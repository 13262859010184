import React from "react"
import Container from "../components/layout/Container"
import Layout from "../components/layout/Layout"
import WideContainer from "../components/layout/WideContainer"
import AlgoliaSearchbar from "../components/searchbar/AlgoliaSearchbar"
import Searchbar from "../components/searchbar/Searchbar"
import MetaData from "../components/seo/MetaData"
import OrganizationStructuredData from "../components/seo/OrganizationStructuredData"

interface Props {
  location: any
}

export default function SearchPage({ location }: Props) {
  return (
    <Layout>
      <MetaData
        title="Search for national parks"
        description="Use the search feature to easily find the information you need. A quick and easy way to browse national parks."
        pageUrl={location.pathname}
      />
      <OrganizationStructuredData />
      <WideContainer center={true}>
        <div className={`container md:px-2 px-2`}>
          <h1 className="mt-6 md:mt-8 mb-0">Find a national park</h1>
          <p className="mt-2 mb-0">
            Each park contains plenty of information. Search for anything
            specific within the parks here.
          </p>
        </div>
      </WideContainer>
      <WideContainer center={true} className="pl-4 pr-2">
        <AlgoliaSearchbar />
      </WideContainer>
    </Layout>
  )
}
