import React from "react"
import { connectSearchBox } from "react-instantsearch-dom"
import { FaSearch } from "react-icons/fa"
import Colors from "../../constants/Colors"

interface Props {
  currentRefinement: any
  refine: any
}

const MySearchbar: React.FunctionComponent<Props> = ({
  currentRefinement,
  refine,
}) => {
  return (
    <form>
      <FaSearch style={searchIcon} size={20} />
      <input
        className="bg-lightest"
        type="search"
        style={textInput}
        value={currentRefinement}
        onChange={event => refine(event.currentTarget.value)}
      />
      <button className="btn" />
    </form>
  )
}

const textInput = {
  boxSizing: "border-box",
  fontSize: 18,
  color: Colors.green,
  paddingTop: 13,
  paddingBottom: 15,
  paddingRight: 13,
  paddingLeft: 50,
  outline: "none",
  width: "100%",
  fontWeight: "normal",
  borderRadius: 5,
} as React.CSSProperties

const searchIcon = {
  color: Colors.green,
  position: "relative",
  top: 38,
  left: 14,
} as React.CSSProperties

export default connectSearchBox(MySearchbar)
